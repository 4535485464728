import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Background_Img from '../assets/Background-img_AG.png'
const ArtGallery = () => {
  return (
    <Wrapper>
      <h1>Coming Soon!</h1>
      <h2>Art Gallery</h2>
      <Link className='link-btn btn' to='/'>Click here to return to home page</Link>
    </Wrapper>
  )
}

const Wrapper = styled.section`
background-image:url(${Background_Img});
  background-repeat: no-repeat;
  background-position: center;
   background-size: cover;
height:100vh;
text-align: center;
color: white;
display: flex;
flex-direction:column;
justify-content: center;
.link-btn{
  width:350px;
  margin: 2rem auto;
  }
  h1{
margin: 2rem 0;
}
`
export default ArtGallery
